import moment from 'moment';
import { getOrdinalSuffix } from '../constants';

export const formatDate = (timeStampDate) => {
  return moment(timeStampDate).format('Do MMM YYYY, h:mm a');
};

export const formatDateWtTime = (timeStampDate) => {
  return moment(timeStampDate).format('Do MMM YYYY');
};

export const formatDateUnix = (timeStampDate) => {
  return moment.unix(timeStampDate).format('Do MMM YYYY, h:mm a');
};

export const formatDateUnixWtTime = (timeStampDate) => {
  return moment.unix(timeStampDate).format('Do MMM YYYY');
};

export const showTrialTime = (time) => {
  const sevenDay = moment().add(7, 'days');
  const cTime = moment(time);

  let res = sevenDay.diff(cTime, 'days');
  if (res > 1) {
    return `${res} day/s`;
  }
  res = sevenDay.diff(cTime, 'minutes');
  if (res > 1) {
    return `${res} minute/s`;
  }

  return '';
};

export const formatProjectDate = (date) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  const day = date.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);
  return formattedDate.replace(/\b\d+\b/, (match) => match + ordinalSuffix);
};
