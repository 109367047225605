import {
  HOME_CHANGE_TRANSFER_REQUEST_BEGIN,
  HOME_CHANGE_TRANSFER_REQUEST_SUCCESS,
  HOME_CHANGE_TRANSFER_REQUEST_FAILURE,
  HOME_CHANGE_TRANSFER_REQUEST_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';
import { toast } from 'react-toastify';

export function changeTransferRequest(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CHANGE_TRANSFER_REQUEST_BEGIN,
    });

    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/${args.projectId}/change-request/${args.status}`,
        {},
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CHANGE_TRANSFER_REQUEST_SUCCESS,
            data: res,
          });
          resolve(res);
          if (res.status === 200) {
            switch (args.status) {
              case 'APPROVED':
                toast.success('Transfer Request Approved Successfully');
                break;
              case 'REJECTED_BY_RECEIVER':
                toast.success('Transfer Request Rejected Successfully');
                break;
              case 'CANCELLED_BY_SENDER':
                toast.success('Transfer Request Cancelled Successfully');
                break;
            }
          }
        },
        (err) => {
          dispatch({
            type: HOME_CHANGE_TRANSFER_REQUEST_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else {
            toast.error('Oops! Something went wrong');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissChangeTransferRequestError() {
  return {
    type: HOME_CHANGE_TRANSFER_REQUEST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CHANGE_TRANSFER_REQUEST_BEGIN:
      return {
        ...state,
        changeTransferRequestPending: true,
        changeTransferRequestError: null,
      };

    case HOME_CHANGE_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        changeTransferRequestPending: false,
        changeTransferRequestError: null,
      };

    case HOME_CHANGE_TRANSFER_REQUEST_FAILURE:
      return {
        ...state,
        changeTransferRequestPending: false,
        changeTransferRequestError: action.data.error,
      };

    case HOME_CHANGE_TRANSFER_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        changeTransferRequestError: null,
      };

    default:
      return state;
  }
}
