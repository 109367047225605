import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Layout from './Layout';

export class GithubAuth extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log('code :>> ', code);
    const { actions, history } = this.props;
    actions.githubAuthenticate({ code }).then((data) => {
      console.log('data :>> ', data);
      history.push(`/profile`);
    });
  }

  render() {
    return (
      <Layout currentPage="Github">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-12">
                <div className="card h-100 shadow-lg">
                  <div className="card-header pb-0 p-3">
                    <div className="row">
                      <div className="col-md-8 d-flex align-items-center">
                        <h4 className="mb-0">Connect Github</h4>
                      </div>
                    </div>
                  </div>
                  <hr className="dark horizontal my-3" />
                  <div className="card-body">Wait while we are verifying...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GithubAuth);
