import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomLoader from '../common/CustomLoader';
import { inviteTeamValidationSchema } from '../common/utils/form-validation';
export class TransferOwnership extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    try {
      const { email } = values;
      const { home, actions } = this.props;
      const { project } = home;
      const { uuid } = project ? project : {};
      await actions.transferOwnership({ projectId: uuid, email });
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    const { home } = this.props;
    const { transferOwnershipPending } = home;
    return (
      <>
        <div className="card-header">
          <h4>Transfer Ownership</h4>
          <p>
            Transfer your projects and their full ownership to another DrapCode account with a click
            of a button.
          </p>
        </div>
        <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
        <Formik
          initialValues={{ email: '' }}
          validationSchema={inviteTeamValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {({ isSubmitting, touched, errors }) => (
            <Form>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email"
                        className={`form-control ${
                          touched.email && errors.email ? 'is-invalid' : ''
                        }`}
                        style={{
                          border: '1px solid #ced4da',
                          borderRadius: '0.25rem',
                          padding: '0.375rem 0.75rem',
                        }}
                      />
                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-success btn-md text-white"
                  disabled={isSubmitting || transferOwnershipPending}
                >
                  <i className="material-icons text-lg position-relative">swap_horiz</i> Transfer
                  <CustomLoader loading={transferOwnershipPending || isSubmitting} />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferOwnership);
