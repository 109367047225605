import React from 'react';
import { BUILDER_FREE, getStudioPlanFeature, OrgPlanOptions } from '../../common/constants';
import HTMLReactParser from 'html-react-parser';

const PlanDetail = ({
  planName,
  country,
  usdPrice,
  drapcodePlan,
  stripePlan,
  subscription,
  handleUpgrade,
  handleCancelUpgrade,
  showContactUs,
}) => {
  const getPriceAndCurrency = (usdPrice) => {
    return (
      <>
        $<span className="price">{usdPrice}</span>
      </>
    );
  };

  const getSubscriptionLevel = (plan) => (plan ? parseInt(plan.split('BUILDER_')[1]) : 0);

  const buttonText = () => {
    const currentLevel = getSubscriptionLevel(subscription);
    const targetLevel = getSubscriptionLevel(drapcodePlan);

    if (currentLevel === targetLevel) return '';

    return !subscription || subscription === BUILDER_FREE || currentLevel < targetLevel ? (
      <>
        <i className="material-icons text-lg position-relative">upgrade</i> Upgrade
      </>
    ) : (
      <>
        <i className="material-icons text-lg position-relative">vertical_align_bottom</i> Downgrade
      </>
    );
  };

  const showButtonOrMessage = () => {
    if (subscription === drapcodePlan) {
      const planMessage = OrgPlanOptions.find((plan) => plan.value === subscription);
      return (
        <div>
          <div className="d-flex align-items-center text-success mt-2 justify-content-center">
            <i className="material-icons opacity-10 fs-3">check_circle</i> &nbsp; Subscribed to
            &nbsp;<strong>{planMessage.label}</strong>
          </div>
          <button className="btn btn-danger btn-lg text-white" onClick={handleCancelUpgrade}>
            <i className="material-icons">cancel</i> Cancel
          </button>
        </div>
      );
    } else if (showContactUs) {
      return (
        <div className="d-flex justify-content-center mt-4">
          <a
            href="https://drapcode.com/contact-us"
            target="_blank"
            className="btn btn-success btn-lg text-white"
          >
            <i className="material-icons">mail</i> Contact Us
          </a>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-success btn-lg text-white"
            onClick={() => handleUpgrade(drapcodePlan, stripePlan)}
          >
            {buttonText()}
          </button>
        </div>
      );
    }
  };

  return (
    <div className="card card-blog card-plain shadow-lg move-on-hover">
      <div className="card-body p-3">
        <h4 className="mb-3 text-center">{planName}</h4>
        <div className="text-center">
          <h5 className="d-inline-block">{getPriceAndCurrency(usdPrice)}</h5>
          <h5 className="price-detail-color d-inline-block my-2">/app/month</h5>
          <div className="text-center mb-2">
            {HTMLReactParser(getStudioPlanFeature(drapcodePlan))}
          </div>
          {showButtonOrMessage()}
        </div>
        <hr className="dark horizontal my-3" />
        <div className="text-center mb-3">
          <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
            <span className="price-color">View Details</span>&nbsp;
            <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PlanDetail;
