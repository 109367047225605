export const HOME_GET_ALL_PROJECTS_BEGIN = 'HOME_GET_ALL_PROJECTS_BEGIN';
export const HOME_GET_ALL_PROJECTS_SUCCESS = 'HOME_GET_ALL_PROJECTS_SUCCESS';
export const HOME_GET_ALL_PROJECTS_FAILURE = 'HOME_GET_ALL_PROJECTS_FAILURE';
export const HOME_GET_ALL_PROJECTS_DISMISS_ERROR = 'HOME_GET_ALL_PROJECTS_DISMISS_ERROR';
export const HOME_CLIENT_DETAILS_BEGIN = 'HOME_CLIENT_DETAILS_BEGIN';
export const HOME_CLIENT_DETAILS_SUCCESS = 'HOME_CLIENT_DETAILS_SUCCESS';
export const HOME_CLIENT_DETAILS_FAILURE = 'HOME_CLIENT_DETAILS_FAILURE';
export const HOME_CLIENT_DETAILS_DISMISS_ERROR = 'HOME_CLIENT_DETAILS_DISMISS_ERROR';
export const HOME_CREATE_CLIENT_PROJECT_BEGIN = 'HOME_CREATE_CLIENT_PROJECT_BEGIN';
export const HOME_CREATE_CLIENT_PROJECT_SUCCESS = 'HOME_CREATE_CLIENT_PROJECT_SUCCESS';
export const HOME_CREATE_CLIENT_PROJECT_FAILURE = 'HOME_CREATE_CLIENT_PROJECT_FAILURE';
export const HOME_CREATE_CLIENT_PROJECT_DISMISS_ERROR = 'HOME_CREATE_CLIENT_PROJECT_DISMISS_ERROR';
export const HOME_FETCH_PROJECT_BEGIN = 'HOME_FETCH_PROJECT_BEGIN';
export const HOME_FETCH_PROJECT_SUCCESS = 'HOME_FETCH_PROJECT_SUCCESS';
export const HOME_FETCH_PROJECT_FAILURE = 'HOME_FETCH_PROJECT_FAILURE';
export const HOME_FETCH_PROJECT_DISMISS_ERROR = 'HOME_FETCH_PROJECT_DISMISS_ERROR';
export const HOME_DELETE_CLIENT_PROJECT_BEGIN = 'HOME_DELETE_CLIENT_PROJECT_BEGIN';
export const HOME_DELETE_CLIENT_PROJECT_SUCCESS = 'HOME_DELETE_CLIENT_PROJECT_SUCCESS';
export const HOME_DELETE_CLIENT_PROJECT_FAILURE = 'HOME_DELETE_CLIENT_PROJECT_FAILURE';
export const HOME_DELETE_CLIENT_PROJECT_DISMISS_ERROR = 'HOME_DELETE_CLIENT_PROJECT_DISMISS_ERROR';
export const HOME_UPDATE_CLIENT_PROJECT_BEGIN = 'HOME_UPDATE_CLIENT_PROJECT_BEGIN';
export const HOME_UPDATE_CLIENT_PROJECT_SUCCESS = 'HOME_UPDATE_CLIENT_PROJECT_SUCCESS';
export const HOME_UPDATE_CLIENT_PROJECT_FAILURE = 'HOME_UPDATE_CLIENT_PROJECT_FAILURE';
export const HOME_UPDATE_CLIENT_PROJECT_DISMISS_ERROR = 'HOME_UPDATE_CLIENT_PROJECT_DISMISS_ERROR';
export const HOME_CHANGE_PROJECT_PERMISSION_BEGIN = 'HOME_CHANGE_PROJECT_PERMISSION_BEGIN';
export const HOME_CHANGE_PROJECT_PERMISSION_SUCCESS = 'HOME_CHANGE_PROJECT_PERMISSION_SUCCESS';
export const HOME_CHANGE_PROJECT_PERMISSION_FAILURE = 'HOME_CHANGE_PROJECT_PERMISSION_FAILURE';
export const HOME_CHANGE_PROJECT_PERMISSION_DISMISS_ERROR =
  'HOME_CHANGE_PROJECT_PERMISSION_DISMISS_ERROR';
export const HOME_TRANSFER_OWNERSHIP_BEGIN = 'HOME_TRANSFER_OWNERSHIP_BEGIN';
export const HOME_TRANSFER_OWNERSHIP_SUCCESS = 'HOME_TRANSFER_OWNERSHIP_SUCCESS';
export const HOME_TRANSFER_OWNERSHIP_FAILURE = 'HOME_TRANSFER_OWNERSHIP_FAILURE';
export const HOME_TRANSFER_OWNERSHIP_DISMISS_ERROR = 'HOME_TRANSFER_OWNERSHIP_DISMISS_ERROR';
export const HOME_INVITE_TEAM_MEMBER_BEGIN = 'HOME_INVITE_TEAM_MEMBER_BEGIN';
export const HOME_INVITE_TEAM_MEMBER_SUCCESS = 'HOME_INVITE_TEAM_MEMBER_SUCCESS';
export const HOME_INVITE_TEAM_MEMBER_FAILURE = 'HOME_INVITE_TEAM_MEMBER_FAILURE';
export const HOME_INVITE_TEAM_MEMBER_DISMISS_ERROR = 'HOME_INVITE_TEAM_MEMBER_DISMISS_ERROR';
export const HOME_TEAM_MEMBER_LIST_BEGIN = 'HOME_TEAM_MEMBER_LIST_BEGIN';
export const HOME_TEAM_MEMBER_LIST_SUCCESS = 'HOME_TEAM_MEMBER_LIST_SUCCESS';
export const HOME_TEAM_MEMBER_LIST_FAILURE = 'HOME_TEAM_MEMBER_LIST_FAILURE';
export const HOME_TEAM_MEMBER_LIST_DISMISS_ERROR = 'HOME_TEAM_MEMBER_LIST_DISMISS_ERROR';
export const HOME_GET_VERSIONS_BEGIN = 'HOME_GET_VERSIONS_BEGIN';
export const HOME_GET_VERSIONS_SUCCESS = 'HOME_GET_VERSIONS_SUCCESS';
export const HOME_GET_VERSIONS_FAILURE = 'HOME_GET_VERSIONS_FAILURE';
export const HOME_GET_VERSIONS_DISMISS_ERROR = 'HOME_GET_VERSIONS_DISMISS_ERROR';
export const HOME_TEAM_MEMBER_PENDING_REQUESTS_BEGIN = 'HOME_TEAM_MEMBER_PENDING_REQUESTS_BEGIN';
export const HOME_TEAM_MEMBER_PENDING_REQUESTS_SUCCESS =
  'HOME_TEAM_MEMBER_PENDING_REQUESTS_SUCCESS';
export const HOME_TEAM_MEMBER_PENDING_REQUESTS_FAILURE =
  'HOME_TEAM_MEMBER_PENDING_REQUESTS_FAILURE';
export const HOME_TEAM_MEMBER_PENDING_REQUESTS_DISMISS_ERROR =
  'HOME_TEAM_MEMBER_PENDING_REQUESTS_DISMISS_ERROR';
export const HOME_REMOVE_TEAM_MEMEBER_BEGIN = 'HOME_REMOVE_TEAM_MEMEBER_BEGIN';
export const HOME_REMOVE_TEAM_MEMEBER_SUCCESS = 'HOME_REMOVE_TEAM_MEMEBER_SUCCESS';
export const HOME_REMOVE_TEAM_MEMEBER_FAILURE = 'HOME_REMOVE_TEAM_MEMEBER_FAILURE';
export const HOME_REMOVE_TEAM_MEMEBER_DISMISS_ERROR = 'HOME_REMOVE_TEAM_MEMEBER_DISMISS_ERROR';
export const HOME_PROJECT_ACTIVITIES_BEGIN = 'HOME_PROJECT_ACTIVITIES_BEGIN';
export const HOME_PROJECT_ACTIVITIES_SUCCESS = 'HOME_PROJECT_ACTIVITIES_SUCCESS';
export const HOME_PROJECT_ACTIVITIES_FAILURE = 'HOME_PROJECT_ACTIVITIES_FAILURE';
export const HOME_PROJECT_ACTIVITIES_DISMISS_ERROR = 'HOME_PROJECT_ACTIVITIES_DISMISS_ERROR';
export const HOME_CLIENT_ORGANIZATION_BEGIN = 'HOME_CLIENT_ORGANIZATION_BEGIN';
export const HOME_CLIENT_ORGANIZATION_SUCCESS = 'HOME_CLIENT_ORGANIZATION_SUCCESS';
export const HOME_CLIENT_ORGANIZATION_FAILURE = 'HOME_CLIENT_ORGANIZATION_FAILURE';
export const HOME_CLIENT_ORGANIZATION_DISMISS_ERROR = 'HOME_CLIENT_ORGANIZATION_DISMISS_ERROR';
export const HOME_UPDATE_PROFILE_BEGIN = 'HOME_UPDATE_PROFILE_BEGIN';
export const HOME_UPDATE_PROFILE_SUCCESS = 'HOME_UPDATE_PROFILE_SUCCESS';
export const HOME_UPDATE_PROFILE_FAILURE = 'HOME_UPDATE_PROFILE_FAILURE';
export const HOME_UPDATE_PROFILE_DISMISS_ERROR = 'HOME_UPDATE_PROFILE_DISMISS_ERROR';
export const HOME_DELETE_CLIENT_PROFILE_BEGIN = 'HOME_DELETE_CLIENT_PROFILE_BEGIN';
export const HOME_DELETE_CLIENT_PROFILE_SUCCESS = 'HOME_DELETE_CLIENT_PROFILE_SUCCESS';
export const HOME_DELETE_CLIENT_PROFILE_FAILURE = 'HOME_DELETE_CLIENT_PROFILE_FAILURE';
export const HOME_DELETE_CLIENT_PROFILE_DISMISS_ERROR = 'HOME_DELETE_CLIENT_PROFILE_DISMISS_ERROR';
export const HOME_CLIENT_PROJECTS_BEGIN = 'HOME_CLIENT_PROJECTS_BEGIN';
export const HOME_CLIENT_PROJECTS_SUCCESS = 'HOME_CLIENT_PROJECTS_SUCCESS';
export const HOME_CLIENT_PROJECTS_FAILURE = 'HOME_CLIENT_PROJECTS_FAILURE';
export const HOME_CLIENT_PROJECTS_DISMISS_ERROR = 'HOME_CLIENT_PROJECTS_DISMISS_ERROR';
export const HOME_GET_TEMPLATES_BEGIN = 'HOME_GET_TEMPLATES_BEGIN';
export const HOME_GET_TEMPLATES_SUCCESS = 'HOME_GET_TEMPLATES_SUCCESS';
export const HOME_GET_TEMPLATES_FAILURE = 'HOME_GET_TEMPLATES_FAILURE';
export const HOME_GET_TEMPLATES_DISMISS_ERROR = 'HOME_GET_TEMPLATES_DISMISS_ERROR';
export const HOME_CHECK_CLONING_BEGIN = 'HOME_CHECK_CLONING_BEGIN';
export const HOME_CHECK_CLONING_SUCCESS = 'HOME_CHECK_CLONING_SUCCESS';
export const HOME_CHECK_CLONING_FAILURE = 'HOME_CHECK_CLONING_FAILURE';
export const HOME_CHECK_CLONING_DISMISS_ERROR = 'HOME_CHECK_CLONING_DISMISS_ERROR';
export const HOME_CLONE_PROJECT_BEGIN = 'HOME_CLONE_PROJECT_BEGIN';
export const HOME_CLONE_PROJECT_SUCCESS = 'HOME_CLONE_PROJECT_SUCCESS';
export const HOME_CLONE_PROJECT_FAILURE = 'HOME_CLONE_PROJECT_FAILURE';
export const HOME_CLONE_PROJECT_DISMISS_ERROR = 'HOME_CLONE_PROJECT_DISMISS_ERROR';
export const HOME_CLONING_REQUEST_BEGIN = 'HOME_CLONING_REQUEST_BEGIN';
export const HOME_CLONING_REQUEST_SUCCESS = 'HOME_CLONING_REQUEST_SUCCESS';
export const HOME_CLONING_REQUEST_FAILURE = 'HOME_CLONING_REQUEST_FAILURE';
export const HOME_CLONING_REQUEST_DISMISS_ERROR = 'HOME_CLONING_REQUEST_DISMISS_ERROR';
export const HOME_CLONE_PENDING_REQUEST_BEGIN = 'HOME_CLONE_PENDING_REQUEST_BEGIN';
export const HOME_CLONE_PENDING_REQUEST_SUCCESS = 'HOME_CLONE_PENDING_REQUEST_SUCCESS';
export const HOME_CLONE_PENDING_REQUEST_FAILURE = 'HOME_CLONE_PENDING_REQUEST_FAILURE';
export const HOME_CLONE_PENDING_REQUEST_DISMISS_ERROR = 'HOME_CLONE_PENDING_REQUEST_DISMISS_ERROR';
export const HOME_PROJECT_CLONE_STATUS_BEGIN = 'HOME_PROJECT_CLONE_STATUS_BEGIN';
export const HOME_PROJECT_CLONE_STATUS_SUCCESS = 'HOME_PROJECT_CLONE_STATUS_SUCCESS';
export const HOME_PROJECT_CLONE_STATUS_FAILURE = 'HOME_PROJECT_CLONE_STATUS_FAILURE';
export const HOME_PROJECT_CLONE_STATUS_DISMISS_ERROR = 'HOME_PROJECT_CLONE_STATUS_DISMISS_ERROR';
export const HOME_CLIENT_PENDING_REQUEST_BEGIN = 'HOME_CLIENT_PENDING_REQUEST_BEGIN';
export const HOME_CLIENT_PENDING_REQUEST_SUCCESS = 'HOME_CLIENT_PENDING_REQUEST_SUCCESS';
export const HOME_CLIENT_PENDING_REQUEST_FAILURE = 'HOME_CLIENT_PENDING_REQUEST_FAILURE';
export const HOME_CLIENT_PENDING_REQUEST_DISMISS_ERROR =
  'HOME_CLIENT_PENDING_REQUEST_DISMISS_ERROR';
export const HOME_STRIPE_PAYMENT_BEGIN = 'HOME_STRIPE_PAYMENT_BEGIN';
export const HOME_STRIPE_PAYMENT_SUCCESS = 'HOME_STRIPE_PAYMENT_SUCCESS';
export const HOME_STRIPE_PAYMENT_FAILURE = 'HOME_STRIPE_PAYMENT_FAILURE';
export const HOME_STRIPE_PAYMENT_DISMISS_ERROR = 'HOME_STRIPE_PAYMENT_DISMISS_ERROR';
export const HOME_CHANGE_TEAM_MEMBER_REQUEST_BEGIN = 'HOME_CHANGE_TEAM_MEMBER_REQUEST_BEGIN';
export const HOME_CHANGE_TEAM_MEMBER_REQUEST_SUCCESS = 'HOME_CHANGE_TEAM_MEMBER_REQUEST_SUCCESS';
export const HOME_CHANGE_TEAM_MEMBER_REQUEST_FAILURE = 'HOME_CHANGE_TEAM_MEMBER_REQUEST_FAILURE';
export const HOME_CHANGE_TEAM_MEMBER_REQUEST_DISMISS_ERROR =
  'HOME_CHANGE_TEAM_MEMBER_REQUEST_DISMISS_ERROR';
export const HOME_LOAD_PENDING_INVITE_BEGIN = 'HOME_LOAD_PENDING_INVITE_BEGIN';
export const HOME_LOAD_PENDING_INVITE_SUCCESS = 'HOME_LOAD_PENDING_INVITE_SUCCESS';
export const HOME_LOAD_PENDING_INVITE_FAILURE = 'HOME_LOAD_PENDING_INVITE_FAILURE';
export const HOME_LOAD_PENDING_INVITE_DISMISS_ERROR = 'HOME_LOAD_PENDING_INVITE_DISMISS_ERROR';
export const HOME_CHANGE_REQUEST_STATUS_BEGIN = 'HOME_CHANGE_REQUEST_STATUS_BEGIN';
export const HOME_CHANGE_REQUEST_STATUS_SUCCESS = 'HOME_CHANGE_REQUEST_STATUS_SUCCESS';
export const HOME_CHANGE_REQUEST_STATUS_FAILURE = 'HOME_CHANGE_REQUEST_STATUS_FAILURE';
export const HOME_CHANGE_REQUEST_STATUS_DISMISS_ERROR = 'HOME_CHANGE_REQUEST_STATUS_DISMISS_ERROR';
export const HOME_LOAD_MEMBER_ORGS_BEGIN = 'HOME_LOAD_MEMBER_ORGS_BEGIN';
export const HOME_LOAD_MEMBER_ORGS_SUCCESS = 'HOME_LOAD_MEMBER_ORGS_SUCCESS';
export const HOME_LOAD_MEMBER_ORGS_FAILURE = 'HOME_LOAD_MEMBER_ORGS_FAILURE';
export const HOME_LOAD_MEMBER_ORGS_DISMISS_ERROR = 'HOME_LOAD_MEMBER_ORGS_DISMISS_ERROR';
export const HOME_LOAD_ORG_MEMBERS_BEGIN = 'HOME_LOAD_ORG_MEMBERS_BEGIN';
export const HOME_LOAD_ORG_MEMBERS_SUCCESS = 'HOME_LOAD_ORG_MEMBERS_SUCCESS';
export const HOME_LOAD_ORG_MEMBERS_FAILURE = 'HOME_LOAD_ORG_MEMBERS_FAILURE';
export const HOME_LOAD_ORG_MEMBERS_DISMISS_ERROR = 'HOME_LOAD_ORG_MEMBERS_DISMISS_ERROR';
export const HOME_FETCH_INVOICE_BEGIN = 'HOME_FETCH_INVOICE_BEGIN';
export const HOME_FETCH_INVOICE_SUCCESS = 'HOME_FETCH_INVOICE_SUCCESS';
export const HOME_FETCH_INVOICE_FAILURE = 'HOME_FETCH_INVOICE_FAILURE';
export const HOME_FETCH_INVOICE_DISMISS_ERROR = 'HOME_FETCH_INVOICE_DISMISS_ERROR';
export const HOME_FETCH_LINE_ITEMS_BEGIN = 'HOME_FETCH_LINE_ITEMS_BEGIN';
export const HOME_FETCH_LINE_ITEMS_SUCCESS = 'HOME_FETCH_LINE_ITEMS_SUCCESS';
export const HOME_FETCH_LINE_ITEMS_FAILURE = 'HOME_FETCH_LINE_ITEMS_FAILURE';
export const HOME_FETCH_LINE_ITEMS_DISMISS_ERROR = 'HOME_FETCH_LINE_ITEMS_DISMISS_ERROR';
export const HOME_VERIFY_PAYMENT_STATUS_BEGIN = 'HOME_VERIFY_PAYMENT_STATUS_BEGIN';
export const HOME_VERIFY_PAYMENT_STATUS_SUCCESS = 'HOME_VERIFY_PAYMENT_STATUS_SUCCESS';
export const HOME_VERIFY_PAYMENT_STATUS_FAILURE = 'HOME_VERIFY_PAYMENT_STATUS_FAILURE';
export const HOME_VERIFY_PAYMENT_STATUS_DISMISS_ERROR = 'HOME_VERIFY_PAYMENT_STATUS_DISMISS_ERROR';
export const HOME_CHANGE_PROJECT_STATUS_BEGIN = 'HOME_CHANGE_PROJECT_STATUS_BEGIN';
export const HOME_CHANGE_PROJECT_STATUS_SUCCESS = 'HOME_CHANGE_PROJECT_STATUS_SUCCESS';
export const HOME_CHANGE_PROJECT_STATUS_FAILURE = 'HOME_CHANGE_PROJECT_STATUS_FAILURE';
export const HOME_CHANGE_PROJECT_STATUS_DISMISS_ERROR = 'HOME_CHANGE_PROJECT_STATUS_DISMISS_ERROR';
export const HOME_HOSTING_PROJECT_SUBSCRIPTION_BEGIN = 'HOME_HOSTING_PROJECT_SUBSCRIPTION_BEGIN';
export const HOME_HOSTING_PROJECT_SUBSCRIPTION_SUCCESS = 'HOME_HOSTING_PROJECT_SUBSCRIPTION_SUCCESS';
export const HOME_HOSTING_PROJECT_SUBSCRIPTION_FAILURE = 'HOME_HOSTING_PROJECT_SUBSCRIPTION_FAILURE';
export const HOME_HOSTING_PROJECT_SUBSCRIPTION_DISMISS_ERROR = 'HOME_HOSTING_PROJECT_SUBSCRIPTION_DISMISS_ERROR';
export const HOME_CANCEL_UPGRADE_BEGIN = 'HOME_CANCEL_UPGRADE_BEGIN';
export const HOME_CANCEL_UPGRADE_SUCCESS = 'HOME_CANCEL_UPGRADE_SUCCESS';
export const HOME_CANCEL_UPGRADE_FAILURE = 'HOME_CANCEL_UPGRADE_FAILURE';
export const HOME_CANCEL_UPGRADE_DISMISS_ERROR = 'HOME_CANCEL_UPGRADE_DISMISS_ERROR';
export const HOME_LOAD_PROJECT_STATS_BEGIN = 'HOME_LOAD_PROJECT_STATS_BEGIN';
export const HOME_LOAD_PROJECT_STATS_SUCCESS = 'HOME_LOAD_PROJECT_STATS_SUCCESS';
export const HOME_LOAD_PROJECT_STATS_FAILURE = 'HOME_LOAD_PROJECT_STATS_FAILURE';
export const HOME_LOAD_PROJECT_STATS_DISMISS_ERROR = 'HOME_LOAD_PROJECT_STATS_DISMISS_ERROR';
export const HOME_LOAD_TOTP_DATA_BEGIN = 'HOME_LOAD_TOTP_DATA_BEGIN';
export const HOME_LOAD_TOTP_DATA_SUCCESS = 'HOME_LOAD_TOTP_DATA_SUCCESS';
export const HOME_LOAD_TOTP_DATA_FAILURE = 'HOME_LOAD_TOTP_DATA_FAILURE';
export const HOME_LOAD_TOTP_DATA_DISMISS_ERROR = 'HOME_LOAD_TOTP_DATA_DISMISS_ERROR';
export const HOME_VERIFY_TOTP_CODE_BEGIN = 'HOME_VERIFY_TOTP_CODE_BEGIN';
export const HOME_VERIFY_TOTP_CODE_SUCCESS = 'HOME_VERIFY_TOTP_CODE_SUCCESS';
export const HOME_VERIFY_TOTP_CODE_FAILURE = 'HOME_VERIFY_TOTP_CODE_FAILURE';
export const HOME_VERIFY_TOTP_CODE_DISMISS_ERROR = 'HOME_VERIFY_TOTP_CODE_DISMISS_ERROR';
export const HOME_DELETE_TOTP_BEGIN = 'HOME_DELETE_TOTP_BEGIN';
export const HOME_DELETE_TOTP_SUCCESS = 'HOME_DELETE_TOTP_SUCCESS';
export const HOME_DELETE_TOTP_FAILURE = 'HOME_DELETE_TOTP_FAILURE';
export const HOME_DELETE_TOTP_DISMISS_ERROR = 'HOME_DELETE_TOTP_DISMISS_ERROR';
export const HOME_CHANGE_CLONE_REQUEST_BEGIN = 'HOME_CHANGE_CLONE_REQUEST_BEGIN';
export const HOME_CHANGE_CLONE_REQUEST_SUCCESS = 'HOME_CHANGE_CLONE_REQUEST_SUCCESS';
export const HOME_CHANGE_CLONE_REQUEST_FAILURE = 'HOME_CHANGE_CLONE_REQUEST_FAILURE';
export const HOME_CHANGE_CLONE_REQUEST_DISMISS_ERROR = 'HOME_CHANGE_CLONE_REQUEST_DISMISS_ERROR';
export const HOME_CHANGE_TRANSFER_REQUEST_BEGIN = 'HOME_CHANGE_TRANSFER_REQUEST_BEGIN';
export const HOME_CHANGE_TRANSFER_REQUEST_SUCCESS = 'HOME_CHANGE_TRANSFER_REQUEST_SUCCESS';
export const HOME_CHANGE_TRANSFER_REQUEST_FAILURE = 'HOME_CHANGE_TRANSFER_REQUEST_FAILURE';
export const HOME_CHANGE_TRANSFER_REQUEST_DISMISS_ERROR = 'HOME_CHANGE_TRANSFER_REQUEST_DISMISS_ERROR';
export const HOME_GITHUB_AUTHENTICATE_BEGIN = 'HOME_GITHUB_AUTHENTICATE_BEGIN';
export const HOME_GITHUB_AUTHENTICATE_SUCCESS = 'HOME_GITHUB_AUTHENTICATE_SUCCESS';
export const HOME_GITHUB_AUTHENTICATE_FAILURE = 'HOME_GITHUB_AUTHENTICATE_FAILURE';
export const HOME_GITHUB_AUTHENTICATE_DISMISS_ERROR = 'HOME_GITHUB_AUTHENTICATE_DISMISS_ERROR';
export const HOME_CANCEL_ORG_UPGRADE_BEGIN = 'HOME_CANCEL_ORG_UPGRADE_BEGIN';
export const HOME_CANCEL_ORG_UPGRADE_SUCCESS = 'HOME_CANCEL_ORG_UPGRADE_SUCCESS';
export const HOME_CANCEL_ORG_UPGRADE_FAILURE = 'HOME_CANCEL_ORG_UPGRADE_FAILURE';
export const HOME_CANCEL_ORG_UPGRADE_DISMISS_ERROR = 'HOME_CANCEL_ORG_UPGRADE_DISMISS_ERROR';
