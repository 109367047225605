import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Layout from './Layout';
import CloneRequests from './transferAndClone/CloneRequests';
import TransferRequests from './transferAndClone/TransferRequests';

export class PendingRequest extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    approveTransferRequest: false,
    cancelTransferRequest: false,
    rejectTransferRequest: false,
    transferProjectUuid: '',
    approveCloneRequest: false,
    cancelCloneRequest: false,
    rejectCloneRequest: false,
    cloneProjectUuid: '',
  };

  changeTransferRequest = (values) => {
    switch (values.status) {
      case 'APPROVED':
        this.setState({ approveTransferRequest: true });
        break;
      case 'REJECTED_BY_RECEIVER':
        this.setState({ rejectTransferRequest: true });
        break;
      case 'CANCELLED_BY_SENDER':
        this.setState({ cancelTransferRequest: true });
        break;
    }

    this.setState({ transferProjectUuid: values.projectId });
    this.props.actions
      .changeTransferRequest({ projectId: values.projectId, status: values.status })
      .then((res) => {
        if (res.status === 200) {
          switch (values.status) {
            case 'APPROVED':
              this.setState({ approveTransferRequest: false });
              break;
            case 'REJECTED_BY_RECEIVER':
              this.setState({ rejectTransferRequest: false });
              break;
            case 'CANCELLED_BY_SENDER':
              this.setState({ cancelTransferRequest: false });
              break;
          }
          this.props.actions.clientPendingRequest();
        }
      });
  };

  changeCloneStatus = (values) => {
    switch (values.status) {
      case 'APPROVED':
        this.setState({ approveCloneRequest: true });
        break;
      case 'REJECTED_BY_RECEIVER':
        this.setState({ rejectCloneRequest: true });
        break;
      case 'CANCELLED_BY_SENDER':
        this.setState({ cancelCloneRequest: true });
        break;
    }

    this.setState({ cloneProjectUuid: values.projectId });

    this.props.actions
      .changeCloneRequest({ projectId: values.projectId, status: values.status })
      .then((res) => {
        if (res.status === 200) {
          switch (values.status) {
            case 'APPROVED':
              this.setState({ approveCloneRequest: false });
              break;
            case 'REJECTED_BY_RECEIVER':
              this.setState({ rejectCloneRequest: false });
              break;
            case 'CANCELLED_BY_SENDER':
              this.setState({ cancelCloneRequest: false });
              break;
          }

          this.props.actions.clonePendingRequest();
        }
      });
  };

  render() {
    const {
      cloningRequest,
      clonePendingRequestPending,
      clientPendingRequestPending,
      transferRequest,
    } = this.props.home;
    return (
      <Layout currentPage="PendingRequests">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              {cloningRequest && cloningRequest.data.length !== 0 && (
                <div className="col-12 mb-4">
                  <CloneRequests
                    cloningRequest={cloningRequest}
                    clonePendingRequestPending={clonePendingRequestPending}
                    changeCloneStatus={this.changeCloneStatus}
                    approveCloneRequest={this.state.approveCloneRequest}
                    cancelCloneRequest={this.state.cancelCloneRequest}
                    rejectCloneRequest={this.state.rejectCloneRequest}
                    cloneProjectUuid={this.state.cloneProjectUuid}
                  />
                </div>
              )}
              {transferRequest && transferRequest.data.length !== 0 && (
                <div className="col-12">
                  <TransferRequests
                    clientPendingRequestPending={clientPendingRequestPending}
                    transferRequest={transferRequest}
                    changeTransferRequest={this.changeTransferRequest}
                    approveTransferRequest={this.state.approveTransferRequest}
                    cancelTransferRequest={this.state.cancelTransferRequest}
                    rejectTransferRequest={this.state.rejectTransferRequest}
                    transferProjectUuid={this.state.transferProjectUuid}
                  />
                </div>
              )}
              {!cloningRequest?.data?.length && !transferRequest?.data?.length ? (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="font-weight-bolder" style={{ textAlign: 'center' }}>
                        No Request Pending
                      </h4>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingRequest);
