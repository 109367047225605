import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import axios from 'axios';
import Swal from 'sweetalert2'; 

class ContactUsModal extends Component {
 
  handleSubmit = async (values) => {
    const { name, email, Company, phoneNumber, countryCode, description } = values;
    const { estimateFeatureId } = this.props;
    const { actions } = this.props;

    const body = {
      name,
      email,
      company: Company,
      contact: `${countryCode}  ${phoneNumber}`, 
      description, 
    };

    console.log("sending body", body);

    const args = {
      body,
      estimateFeatureId,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}line-items/${estimateFeatureId}/quote`,
        body
      );
      await actions.sendMail(args);
      Swal.fire({
        title: 'Thank You :-)',
        text: 'Your form has been submitted successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      this.props.handleClose();
    } catch (error) {
      console.error("error submitting form", error);
      Swal.fire({
        title: 'Error!',
        text: 'There was an error submitting the form. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }

  render() {
    const { showModal, handleClose } = this.props;

    return (
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <div className="card-header w-100 d-flex justify-content-center p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
            <div className="bg-gradient-primary border-radius-lg p-3">
              <h2 className="text-white text-primary mb-0">Let us get back to you.</h2>
            </div>
          </div>
        </Modal.Header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h3 className="mt-4 mb-2 p-3">
                Let's discuss how we may assist you in building more quickly.
              </h3>
              <p className="mb-2 p-3">
                You may be familiar with no-code and would like to give it a try. 
                Or perhaps you have already chosen a pilot project for your company. 
                Our staff is here to assist in either case. Let us arrange a 
                complimentary consultation and a brief discovery call:
              </p>
              <div className="d-flex mx-1">
                <i className="material-icons text-success font-weight-bold">done</i>
                <div className="mx-2 my-auto">
                  <p className="text-sm text-dark">
                    How to create scalable web applications with DrapCode
                  </p>
                </div>
              </div>
              <div className="d-flex mx-1">
                <i className="material-icons text-success font-weight-bold">done</i>
                <div className="mx-2 my-auto">
                  <p className="text-sm text-dark">
                    How implementing NoCode can help you save time and money
                  </p>
                </div>
              </div>
              <div className="d-flex mx-1">
                <i className="material-icons text-success font-weight-bold">done</i>
                <div className="mx-2 my-auto text-dark">
                  <p className="text-sm">
                    How to launch faster to stay ahead of the competition
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Modal.Body>
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    Company: '',
                    phoneNumber: '',
                    countryCode: '',
                    description: '', 
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required('Name is required'),
                    email: Yup.string()
                      .email('Invalid email address')
                      .required('Email is required'),
                    Company: Yup.string().required('Company name is required'),
                    phoneNumber: Yup.string()
                      .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
                      .required('Phone number is required'),
                    description: Yup.string()
                      .required('Description is required') 
                      .max(500, 'Description should not exceed 500 characters'),
                  })}
                  onSubmit={this.handleSubmit}
                >
                  {({ touched, errors, setFieldValue, values, isValid }) => (
                    <Form>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label text-dark">
                          Name
                        </label>
                        <Field
                          type="text"
                          className="form-control border rounded-3 p-2"
                          id="name"
                          name="name"
                          placeholder="Full Name"
                        />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label text-dark">
                          Work Email
                        </label>
                        <Field
                          type="email"
                          className="form-control border rounded-3 p-2"
                          id="email"
                          name="email"
                          placeholder="name@example.com"
                        />
                        <ErrorMessage name="email" component="div" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="Company" className="form-label text-dark">
                          Company
                        </label>
                        <Field
                          type="text"
                          className="form-control border rounded-3 p-2"
                          id="Company"
                          name="Company"
                          placeholder="Company Name"
                        />
                        <ErrorMessage name="Company" component="div" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="phoneNumber" className="form-label text-dark">
                          Phone Number
                        </label>
                        <div className="input-group">
                          <div className="border rounded-3 w-100">
                            <IntlTelInput
                              fieldName="phoneNumber"
                              defaultCountry="us"
                              className="form-control"
                              onPhoneNumberChange={(status, value, countryData) => {
                                setFieldValue('phoneNumber', value);
                                setFieldValue('countryCode', `+${countryData.dialCode}`);
                              }}
                              value={values.phoneNumber}
                              preferredCountries={['us']}
                              containerClassName="intl-tel-input"
                              inputClassName="form-control"
                              dropdownClassName="dropdown-menu"
                            />
                          </div>
                        </div>
                        <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="description" className="form-label text-dark">
                          Description
                        </label>
                        <Field
                          as="textarea"
                          className="form-control border rounded-3 p-2"
                          id="description"
                          name="description"
                          placeholder="Enter a brief description..."
                          rows="3"
                        />
                        <ErrorMessage name="description" component="div" className="text-danger" />
                      </div>

                      <div className="text-center mt-3">
                        <Button className='w-100' type="submit" disabled={!isValid}
                          style={{ backgroundColor: '#1AA6AD' }}>
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ContactUsModal;
