import {
  HOME_CANCEL_ORG_UPGRADE_BEGIN,
  HOME_CANCEL_ORG_UPGRADE_SUCCESS,
  HOME_CANCEL_ORG_UPGRADE_FAILURE,
  HOME_CANCEL_ORG_UPGRADE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';
export function cancelOrgUpgrade(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CANCEL_ORG_UPGRADE_BEGIN,
    });
    const config = builderAuthHeader();
    config.headers['Access-Control-Allow-Origin'] = '*';
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}organizations/cancel/plan`,
        {},
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CANCEL_ORG_UPGRADE_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: HOME_CANCEL_ORG_UPGRADE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCancelOrgUpgradeError() {
  return {
    type: HOME_CANCEL_ORG_UPGRADE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CANCEL_ORG_UPGRADE_BEGIN:
      return {
        ...state,
        cancelOrgUpgradePending: true,
        cancelOrgUpgradeError: null,
      };

    case HOME_CANCEL_ORG_UPGRADE_SUCCESS:
      return {
        ...state,
        cancelOrgUpgradePending: false,
        cancelOrgUpgradeError: null,
        orgClient: action.data,
      };

    case HOME_CANCEL_ORG_UPGRADE_FAILURE:
      return {
        ...state,
        cancelOrgUpgradePending: false,
        cancelOrgUpgradeError: action.data.error,
      };

    case HOME_CANCEL_ORG_UPGRADE_DISMISS_ERROR:
      return {
        ...state,
        cancelOrgUpgradeError: null,
      };

    default:
      return state;
  }
}
