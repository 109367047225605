import axios from 'axios';
import { toast } from 'react-toastify';
import {
  HOME_GITHUB_AUTHENTICATE_BEGIN,
  HOME_GITHUB_AUTHENTICATE_SUCCESS,
  HOME_GITHUB_AUTHENTICATE_FAILURE,
  HOME_GITHUB_AUTHENTICATE_DISMISS_ERROR,
} from './constants';
import { builderAuthHeader } from '../../common/utils/auth';

export function githubAuthenticate(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_GITHUB_AUTHENTICATE_BEGIN,
    });
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/github-authenticate/${args.code}/`,
        {},
        config,
      );
      doRequest.then(
        (res) => {
          if (res.status === 200) {
            const { data } = res;
            if (data.success === true) {
              dispatch({
                type: HOME_GITHUB_AUTHENTICATE_SUCCESS,
                data: data.accessToken,
              });
              toast.success('GitHub authentication successful!');
              resolve(res.data);
            } else {
              toast.error(data.error);
              dispatch({
                type: HOME_GITHUB_AUTHENTICATE_FAILURE,
                data: { error: data.error },
              });
              reject(data.error);
            }
          } else {
            toast.error(res.data);
            dispatch({
              type: HOME_GITHUB_AUTHENTICATE_FAILURE,
              data: { error: res.data },
            });
            reject(res.data);
          }
        },
        (err) => {
          console.log('err :>> ', err);
          dispatch({
            type: HOME_GITHUB_AUTHENTICATE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGithubAuthenticateError() {
  return {
    type: HOME_GITHUB_AUTHENTICATE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GITHUB_AUTHENTICATE_BEGIN:
      return {
        ...state,
        githubAuthenticatePending: true,
        githubAuthenticateError: null,
      };

    case HOME_GITHUB_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        githubAuthenticatePending: false,
        githubAuthenticateError: null,
        githubAuthenticateResponse: action.data,
      };

    case HOME_GITHUB_AUTHENTICATE_FAILURE:
      return {
        ...state,
        githubAuthenticatePending: false,
        githubAuthenticateError: action.data.error,
      };

    case HOME_GITHUB_AUTHENTICATE_DISMISS_ERROR:
      return {
        ...state,
        githubAuthenticateError: null,
      };

    default:
      return state;
  }
}
