import React, { Component } from 'react';
import CustomLoader from '../../common/CustomLoader';
import { formatDate } from '../../common/utils/date-utils';
import cookie from 'react-cookies';
import BeatLoader from 'react-spinners/BeatLoader';

export default class TransferRequests extends Component {
  render() {
    const {
      clientPendingRequestPending,
      transferRequest,
      approveTransferRequest,
      cancelTransferRequest,
      rejectTransferRequest,
      transferProjectUuid,
    } = this.props;
    let senderRequest = [];
    let receiverRequest = [];

    let clientUuid = cookie.load('uuid');
    if (transferRequest) {
      transferRequest.data.forEach((request) => {
        if (request.senderUuid === clientUuid) {
          senderRequest.push(request);
        } else {
          receiverRequest.push(request);
        }
      });
    }

    return (
      <div className="card">
        <div className="card-body">
          <CustomLoader
            size="6px"
            color="#303453"
            sync={true}
            loading={clientPendingRequestPending}
          />

          {/* Transfer Request Created */}
          {!clientPendingRequestPending && (senderRequest?.length ?? 0) > 0 && (
            <>
              <h5 className="font-weight-bolder">Transfer Request Created</h5>
              <hr className="dark horizontal my-4" />
              <div className="table-responsive p-0">
                <table className="table table-bordered align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Project
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Receiver
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Date
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {senderRequest?.map((request) => (
                      <tr key={request.uuid}>
                        <td className="align-middle text-center">
                          {request.project?.name || 'N/A'}
                        </td>
                        <td className="align-middle text-center">
                          {request.receiverName} ({request.receiverUsername})
                        </td>
                        <td className="align-middle text-center">
                          {formatDate(request.dateCreated)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <button
                            className="btn btn-danger text-white btn-sm"
                            onClick={() =>
                              this.props.changeTransferRequest({
                                projectId: request.uuid,
                                status: 'CANCELLED_BY_SENDER',
                              })
                            }
                          >
                            <i className="fa fa-xmark me-sm-1"></i> Cancel
                            {request.uuid === transferProjectUuid && (
                              <BeatLoader
                                cssOverride={{ display: '' }}
                                size={'9px'}
                                margin={'1px'}
                                color={'#344767'}
                                loading={cancelTransferRequest}
                              />
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {/* Transfer Request Received */}
          {!clientPendingRequestPending && (receiverRequest?.length ?? 0) > 0 && (
            <>
              <h5 className="font-weight-bolder mt-5">Transfer Request Received</h5>
              <hr className="dark horizontal my-4" />
              <div className="table-responsive p-0">
                <table className="table table-bordered align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Project
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Sender
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Date
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {receiverRequest?.map((request) => (
                      <tr key={request.uuid}>
                        <td className="align-middle text-center">
                          {request.project?.name || 'N/A'}
                        </td>
                        <td className="align-middle text-center">
                          {request.senderName} ({request.senderUsername})
                        </td>
                        <td className="align-middle text-center">
                          {formatDate(request.dateCreated)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <button
                            className="btn btn-success text-white btn-sm mx-5"
                            onClick={() =>
                              this.props.changeTransferRequest({
                                projectId: request.uuid,
                                status: 'APPROVED',
                              })
                            }
                          >
                            <i className="fa fa-check me-sm-1"></i> Accept
                            {request.uuid === transferProjectUuid && (
                              <BeatLoader
                                cssOverride={{ display: '' }}
                                size={'9px'}
                                margin={'1px'}
                                color={'#344767'}
                                loading={approveTransferRequest}
                              />
                            )}
                          </button>
                          <button
                            className="btn btn-danger text-white btn-sm"
                            onClick={() =>
                              this.props.changeTransferRequest({
                                projectId: request.uuid,
                                status: 'REJECTED_BY_RECEIVER',
                              })
                            }
                          >
                            <i className="fa fa-xmark me-sm-1"></i> Reject
                            {request.uuid === transferProjectUuid && (
                              <BeatLoader
                                cssOverride={{ display: '' }}
                                size={'9px'}
                                margin={'1px'}
                                color={'#344767'}
                                loading={rejectTransferRequest}
                              />
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
